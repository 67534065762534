import { IEnvironment } from '@env/environment.interface';
import packageJson from '../../package.json';

export const environment: IEnvironment = {
  name: 'production',
  production: true,
  API_URL: 'https://api.talent.silae.fr',
  MY_SILAE_LABEL: 'My Silae',
  MY_SILAE_URL: 'https://my.silae.fr',
  POST_HOG_API_KEY: 'phc_pgvQ6FsFQmKoNB1vv5jIsqw9DoRnOwr6fInlAXqwvsH',
  POST_HOG_HOST: 'https://sniffy.my.silae.fr',
  VERSION: packageJson.version,
  WHITELISTED_DOMAIN: 'api.talent.silae.fr',
};
